import { useCallback, useState, useEffect } from 'react';
import { subSleep } from '../AnimationUtilites/sleep';

export default function useFetch(dataGetter, page){
  const [loading, setLoading]   = useState(true)
  const [error, setError]       = useState(false)
  const [hasNext,setHasNext] = useState(null)
  const [dataList, setDataList] = useState([])

  const sendQuery = useCallback(async ()=>{
    await subSleep(1200)
    try {
      await setLoading(true);
      await setError(false);
      const res = await dataGetter({params:{page:page}})
      typeof res.data.results ==='object' && await setDataList((prev)=> [...prev,...res.data.results])
      await setHasNext(i=>res.data.next)
      setLoading(false)
    }catch(ers){
      setError(ers)
    }
  },[page])

  useEffect(()=>{
    sendQuery();
  },[page])

  return { loading, error, hasNext, dataList };
}