import {useState, useEffect, useRef} from 'react'
import '../StyleSheets/admin.css'
import typeIn from '../../../Animations/type_in'
import {isMobile} from 'react-device-detect'
import { authenication } from '../../../Network/request_handlers'
import { reacctBaseURL } from '../../../Utilities/baseURL'

export default function AdminLoginPage(){
  const [mobile,setMobile] = useState(false);
  const[error,setErrors] = useState(false)

  const titleContainer = useRef(null);

  useEffect(()=>{
    setMobile(()=>isMobile)
  },[mobile])

  useEffect(()=>{
    titleContainer.current.innerHTML = ''
    let message = error ? 'Hmm... your email or password is incorrect' : 'Hey is that you? Login so I know it’s real'
    typeIn(message,titleContainer.current)
  },[error])

  const handleSubmit = (e) =>{
    e.preventDefault()
    console.log(e)
    let body = {
      username: e.target.username.value,
      password: e.target.password.value
    }
    authenication(body).then((res)=>{
      localStorage.setItem('Token',res.data.token)
      window.location.replace(`${reacctBaseURL}/Naana/Home`)
    }).catch(ers=>{
      setErrors(i=>true)
      console.log(ers)
    })
  }

  return(
    mobile ?
    <NotForThisDevice/>:
    <div className='admin-container'>
      <div className='admin-overlay'>
        <div className='title-container'>
          <h1 style={{color:`${error && '#E99DAA'}`}}ref={titleContainer}/>
        </div>
        <form className='login-container' onSubmit={handleSubmit}>
          <label>Username/Email</label>
          <input type='text' name='username' onClick={()=>this.focus}/>
          <label>Password</label>
          <div className='password-group'>
            <input type='password' name='password'/>
            <input type='submit' value='Login'/>
          </div>
        </form>
      </div>
    </div>
  )
}

function NotForThisDevice(){
  return(
    <div className='admin-container'>
      <div className='admin-overlay'>
        <div className='title-container'>
          <h1>Please use a desktop to access the admin center.</h1>
        </div>
      </div>
    </div>
  )
}