import {useRef,useEffect} from 'react'
import { subSleep } from '../../../AnimationUtilites/sleep'
import navItemAnimationHandler from '../../../Animations/Navigation/nav_item_animation_handler'

export default function SecondBaseNavItem({tabName,href,animationDelay}){
  const navItemContainer = useRef(null)
  
  useEffect(()=>{
    subSleep(animationDelay+1000).then(
      navItemAnimationHandler(navItemContainer.current)
    )
  },[])

  return(
    <div className='nav-item-2' ref={navItemContainer}>
      <a href={href} className='nav-link'>{tabName}</a>
    </div>
  )
}