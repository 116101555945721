import Modal from 'react-bootstrap/Modal'
import { useRef, useEffect,useState } from 'react'
import './StyleSheets/image-modal.css'
import { baseURL } from '../../../Utilities/baseURL'
import {useMediaQuery} from 'react-responsive'

export default function ImageModal({data,Images,showModal,setShowModal}){
  const [size,setSize]                 = useState('')
  const [mobile,setMobile]             = useState(false);
  const [imageIdx,setImageIdx]         = useState(0);
  const [currentImage,setCurrentImage] = useState(null);

  const {modalData}        = data
  const imageContainer     = useRef(null)

  const isMobile = useMediaQuery({
    query: '(max-width:499px)'
  })

  useEffect(()=>{
    setMobile(()=>isMobile)
  },[mobile,isMobile])

  
  useEffect(()=>{
    if(Images){
      let idx;
      if(modalData?.Image){
        idx = Images.findIndex((idx)=>{
          return idx.Image === modalData.Image
        })
      }else if(modalData?.Cover_Image){
        idx = Images.findIndex((idx)=>{
          return idx.Cover_Image === modalData.Cover_Image
        })
      }else if(typeof(modalData)==='string'){
        idx = Images.findIndex((idx)=>{
          return idx === modalData
        })
      }
      setImageIdx(idx)
    }
  },[Images,modalData])


  useEffect(()=>{
    let imageFromDataList;
    let image ;
    let modalImage;
    imageFromDataList = Images && (Images[imageIdx]?.Image || Images[imageIdx]?.Cover_Image || Images[imageIdx])
    modalImage  =  modalData?.Image || modalData?.Cover_Image || modalData
    
    image = imageFromDataList ? 
    imageFromDataList.includes(baseURL) ? imageFromDataList : `${baseURL}${imageFromDataList}`:
    modalImage && modalImage.includes(baseURL) ? modalImage : `${baseURL}${modalImage}`

    setCurrentImage(image)
  },[imageIdx,Images])

  useEffect(()=>{
    var width = imageContainer.current.width
    var height = imageContainer.current.height
    width > height ? 
    setSize('lg'):
    setSize('md')
  },[size,imageIdx])

  const handleRightClick = ()=>{
    if(imageIdx < Images.length-1){
      let idx = imageIdx+1
      setImageIdx(idx)
    }else{
      setImageIdx(0)
    }
  }

  const handleLeftClick = ()=>{
    if(imageIdx > 0){
      let idx = imageIdx -1
      setImageIdx(idx)
    }else{
      setImageIdx(Images.length-1)
    }
  }

  return(
    <Modal show={showModal} onHide={()=>setShowModal(i=>!i)} size={size}>
        <Modal.Header closeButton/>
        <Modal.Body>
          <div className='modal-image-container'>
            {Images && <section className='pointers'>
              <span onClick={handleLeftClick}>&#8249;</span>
            </section>}
            <section className='image-container'>
              <img src={currentImage} alt={modalData.Title ? modalData.Title : 'Missing Image'} ref={imageContainer}/>
            </section>
            {Images && <section className='pointers'>
              <span onClick={handleRightClick}>&#8250;</span>
            </section>}
          </div>
        </Modal.Body>
    </Modal>
  )
}