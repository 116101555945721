import { useState } from 'react'
import { baseURL } from '../../../Utilities/baseURL'

export const PublicationCard = ({publication,setCurrentPublication,isMobile})=>{
  const [reveal,setReveal] = useState(false)

  const {Cover_Image,Title,Description,id} = publication

  return(
    <div className='publications-card'>
      {!isMobile ?
      <section className='image-container' style={{backgroundImage:`url(${baseURL}${Cover_Image})`}} onMouseEnter={()=>setReveal(true)} onMouseLeave={()=>setReveal(false)}>
        {
          reveal &&
          <section className='description'> 
            <h1>{Title}</h1>
            <br/>
            <p>{Description}</p>
            <button onClick={()=>setCurrentPublication(publication)}>View Publication</button>
          </section>
        }
      </section>
      :<section className='image-container' style={{backgroundImage:`url(${baseURL}${Cover_Image})`}} onClick={()=>setReveal(i=>!i)}>
        {
        reveal &&
        <section className='description'> 
          <h1>{Title}</h1>
          <br/>
          <p>{Description}</p>
          <button onClick={()=>setCurrentPublication(publication)}>View Publication</button>
        </section>
        }
      </section>}
    </div>
  )
}