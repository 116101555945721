import {BrowserRouter as Router,Route,Routes,Switch} from 'react-router-dom'
import { useState,useEffect } from 'react'
import { getPublications } from './Network/request_handlers'
import HighLightsPage from './Components/Home/Highlights/HighLightsPage'
import GalleryPage from './Components/Home/Gallery/GalleryPage'
import AboutPage from './Components/Home/About/AboutPage'
import PolaroidsPage from './Components/Home/Polaroids/PolaroidsPage'
import PublicationsPage from './Components/Home/Publications/PublicationsPage'
import PublicationPage from './Components/Home/Publications/PublicationPage'
import AdminLoginPage from './Components/Admin/AdminComponents/admin_login_page'
import AdminHomePage from './Components/Admin/AdminComponents/admin_home_page'
import AdminHomeEditPage from './Components/Admin/AdminComponents/admin_home_edit_page'
import AdminAboutEditPage from './Components/Admin/AdminComponents/admin_about_edit_page'
import AdminGalleryEditPage from './Components/Admin/AdminComponents/admin_gallery_edit_page'
import AdminPolaroidsEditPage from './Components/Admin/AdminComponents/admin_polaroids_edit_page'
import AdminPublicationsEditPage from './Components/Admin/AdminComponents/admin_publications_edit_page'
import PageNotFound from './Components/General/MultiPurposeScreens/404'
import MensahStudiosFooter from './Components/General/Footer/mensah_studios_footer'

export default function Platform(){
  const [publicationData,setPublicationData]= useState([])

  return(
    <div className='container-fluid p-0'>
      <Router>
        <Routes>
          <Route path='/' element={<HighLightsPage/>} exact/>
          <Route path='Gallery/' element={<GalleryPage/>} exact/>
          <Route path='About/' element={<AboutPage/>} exact/>
          <Route path='Polaroids/' element={<PolaroidsPage/>} exact/>
          <Route path='Publications/' element={<PublicationsPage/>} exact/>
          <Route path='Naana/' element={<AdminLoginPage/>} exact/>
          <Route path='Naana/Home/' element={<AdminHomePage/>} exact/>
          <Route path='Naana/Edit-Home-Page/' element={<AdminHomeEditPage/>} exact/>
          <Route path='Naana/Edit-About-Page/' element={<AdminAboutEditPage/>} exact/>
          <Route path='Naana/Edit-Gallery-Page/' element={<AdminGalleryEditPage/>} exact/>
          <Route path='Naana/Edit-Polaroids-Page/' element={<AdminPolaroidsEditPage/>} exact/>
          <Route path='Naana/Edit-Publications-Page/' element={<AdminPublicationsEditPage/>} exact/>
          <Route path='404/' element={<PageNotFound/>}/>
        </Routes>
      </Router>
      <MensahStudiosFooter/>
    </div>
  )
}