import axios from 'axios'
import {baseURL} from './Utilities/baseURL'

export const apiClient = axios.create(
  {
    baseURL:baseURL,
    timeout: 30000,
    header: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    }
  }
)