import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import { dataConverter } from '../../../Utilities/data_helpers';
import { postGallery } from '../../../Network/request_handlers';
import Spinner from 'react-bootstrap/esm/Spinner';

export default function InputImagesModal({showModal,setShowModal,pageNumber,setPageNumber,imageFormats}){
  const [rawData,setRawData]     = useState({});
  const [submiited,setSubmitted] = useState(false)

  const handleChange = (e)=>{
    var fileList = e.target.files
    let imageHash = new Object()

    for(let i=0; i<fileList.length; i++){
      let type = fileList.item(i).name.split('.')[1].toLowerCase()
      if(imageFormats.includes(type)){
        imageHash[`Image_${i}`] = fileList.item(i)
      }else{
        alert(`It appears there is a file that was not an image (${fileList.item(i).name}). Please try again. Nothing will be saved if you do not try again.`)
        imageHash = new Object()
        break
      }
      
    }
    setRawData(imageHash)
  };

  const handleSubmit = (e)=>{
    e.preventDefault()
    setSubmitted(true)
    let body = dataConverter(rawData)
    postGallery(body).then((res)=>{
      res.status===200 && setShowModal(i=>!i)
    }).catch(ers=>{
      console.error(ers)
    }).finally(()=>{
      setSubmitted(false)
      window.location.reload()
    })
  }
  

  return(
      <Modal show={showModal} onHide={()=>setShowModal(i=>!i)} size='lg'>
        <Modal.Header closeButton/>
        <Modal.Body>
          <form onSubmit={handleSubmit} className='d-flex flex-column justify-content-center align-items-center'>
            <label>Add Images</label>
            <br/>
            <input type='file' name='images' onChange={handleChange} className='mt-4' multiple required/>
            <br/>
            {submiited ? <Spinner animation='border' variant='success'/> : <input type='submit' value='Submit Images' className='mt-4'/>}
          </form>
        </Modal.Body>
    </Modal>
  )
}