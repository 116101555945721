import { inDevelopment } from './dev';
import { acceptanceTest } from './dev';
import { serverAcceptanceTest } from './dev';

let baseURL;
let reacctBaseURL;

if(inDevelopment || acceptanceTest){
  baseURL = 'http://127.0.0.1:8000'
}else if(serverAcceptanceTest){
  baseURL = 'http://66.228.39.146:8000'
}else{
   baseURL = 'https://www.nyawson.com'
}

if(inDevelopment){
  reacctBaseURL = 'http://localhost:3000'
}else if(acceptanceTest){
  reacctBaseURL = 'http://127.0.0.1:8000'
}else if(serverAcceptanceTest){
  reacctBaseURL = 'http://66.228.39.146:8000'
}else{
   reacctBaseURL = 'https://www.nyawson.com'
}

export {baseURL,reacctBaseURL}