import {useRef,useEffect} from 'react'
import { subSleep } from '../../AnimationUtilites/sleep'
import navItemAnimationHandler from '../../Animations/Navigation/nav_item_animation_handler'
export default function MobileNavItem({tabName,href,isClicked,animationDelay}){
  const navItemContainer = useRef(null)
  
  useEffect(()=>{
    if(isClicked){
      subSleep(animationDelay+1000).then(
      navItemAnimationHandler(navItemContainer.current,isClicked,true)
      )
    }
  },[isClicked])

  return(
    <div className='nav-item-3' ref={navItemContainer}>
      <a href={href} className='nav-link'>{tabName}</a>
    </div>
  )
}