import { useState, useRef } from 'react';
import { deleteGallery } from '../../../Network/request_handlers';

export default function FormImageContainer({ImageSrc,onChangeFn,name,id,canDelete,location,data,updateData}){
  const [showDelete, setShowDelete] = useState(false)
  const imageContainer = useRef(null)

  const handleButtonClick = (name='')=>{
    if(location === 'highlight_edit'){
      updateData({
        ...data,
        [name]:'delete_image'
      })
    }else{
      deleteGallery({id: id}).then(res=>{
        imageContainer.current.style.display = 'none'
      }).catch((ers)=>{
        console.log(ers)
      })
    }
  }

  return(
    <div className='image-sumbit-container' onMouseEnter={()=>setShowDelete(true)} onMouseLeave={()=>setShowDelete(false)} ref={imageContainer}>
    {canDelete && showDelete &&
      <div className='delete-image-section'>
        <button onClick={()=>handleButtonClick(name)}>X</button>
      </div>}
      <div className='image-container'>
        <img src={ImageSrc} className='current-or-new-image' alt={name}/>
      </div>
      <input type='file' onChange={onChangeFn} className='image-input-section' name={name} id={id}/>
    </div>
  )
}