import { useEffect, useState} from 'react';
import { tokenKey } from '../../../Utilities/keys';
import { reacctBaseURL,baseURL } from '../../../Utilities/baseURL';
import { getHighLights, updateHighLights } from '../../../Network/request_handlers';
import FormImageContainer from '../../General/ImageContainers/formImageContainer';
import Spinner from 'react-bootstrap/esm/Spinner';
import { dataConverter } from '../../../Utilities/data_helpers';
import '../StyleSheets/admin-home-edit.css'
import { SUPPORTED_IMAGE_EXT } from '../../../Utilities/constants';

export default function AdminHomeEditPage(){
  const [highLights, setHighLights] = useState([])
  const [rawData, setRawData]     = useState({})
  const [submitted,setSubmitted]  = useState(false)

  useEffect(()=>{
    let token = localStorage.getItem('Token')
    tokenKey !== token &&
    window.location.replace(`${reacctBaseURL}/Naana`)
  },[])

  useEffect(()=>{
    getHighLights().then((res)=>{
      setHighLights(res.data[0])
    })
  },[])

  const logOut = ()=>{
    localStorage.removeItem('Token')
    window.location.replace(`${reacctBaseURL}/Naana`)
  }

  const handleChange = (e)=>{
    let nameFirstLetter = e.target.name.charAt(0).toUpperCase()
    let restOfName = e.target.name.slice(1)
    let type = e.target.value.split('.')[1].toLowerCase()

    SUPPORTED_IMAGE_EXT.includes(type) ? setRawData({
      ...rawData,
      [nameFirstLetter+restOfName]: e.target.files[0]
    }): alert('This is not an Image. It wont be saved.')
  };

  const handleSubmit = (e)=>{
    e.preventDefault()
    if(submitted){
      let body = dataConverter(rawData)
      body.append('id',highLights.id)
      
      updateHighLights(body).catch(ers=>{
        setSubmitted(false)
        console.log(ers)
      }).finally(()=>{
        setSubmitted(false)
        window.location.reload()
      })
    }
  }
  
  return(
    <div className='admin-container'>
      <div className='admin-edit-overlay'>
        <div className='admin-home-title-container'>
          <a href={`${reacctBaseURL}/Naana/Home`} className='admin-back-button'>&#8592;</a>
          <h3>Home Page View/Edit</h3>
          <button onClick={logOut}>Log Out</button>
        </div>
        <div className='current-page-iframe-container'>
          <iframe src={reacctBaseURL} title='Home Page Current View'/>
        </div>
        <div className='edit-container'>
          <h1>Images:</h1>
          <form className='edit-container' onSubmit={handleSubmit}>
            {
              Object.keys(highLights).map((img,i)=>{
                return(
                  i !==0 && <FormImageContainer ImageSrc={baseURL+highLights[img]} onChangeFn={handleChange} name={`Image_${i}`} canDelete={true} data={rawData} updateData={setRawData} location={'highlight_edit'} key={i}/>
                )
              })
            }
            <input type='submit' value='Save Changes' className='admin-submit-button' onClick={()=>setSubmitted(true)}/>
          </form>
        </div>
      </div>
    </div>
    )
}