import { useState, useEffect } from 'react';
import { getPolaroids } from '../../../Network/request_handlers';
import SecondNavigationBar from '../../Navigation/second_navigation_bar'
import './StyleSheets/polaroids.css'
import { baseURL } from '../../../Utilities/baseURL';
import ImageModal from '../../General/Modals/imageModal';

export default function PolaroidsPage(){
  const [showModal,setShowModal] = useState(false)
  const [modalData,setModalData] = useState({})
  const [polaroidData,setPolaroidsData] = useState([])

  useEffect(()=>{
    getPolaroids().then((res)=>{
      res.status===200 &&
      setPolaroidsData((i)=>[...i,...res.data])
    })
  },[])
  
  const handleClick = async (data)=>{
    await setModalData({...modalData,modalData:data})
    await setShowModal(i=>!i)
  }

  return(
    <div className='screen-container'>
      <title>Polaroids and Video</title>
      <SecondNavigationBar/>
      <div className='polaroids-container'>
        <h1 className='polaroids-title'>Polaroids & Videos</h1>
        <div className='polaroids'>
          {polaroidData.map((img,i)=>{
            let hasNoImage = !img?.Image
            return(
              (img.Image && !hasNoImage) &&
              <div className='polaroids-img-container' key={i}>
                <img src={`${baseURL}${img.Image}`} alt={i} onClick={()=>handleClick(img)}/>
              </div>
            )
          })}
        </div>
        <div className='polaroid-videos'>
          {polaroidData.map((img,i)=>{
              let hasNoImage = !img?.Image
              return(
                (img.Video && hasNoImage) &&
                <div className='polaroid-video-container' key={i}>
                  <video src={`${baseURL}${img.Video}`} alt={i} controls autoPlay/>
                </div>
              )
          })}
          {polaroidData.map((img,i)=>{
              let hasNoImage = !img?.Image
              return(
                (img.External_Video && hasNoImage) &&
                <div className='polaroid-video-container' key={i}>
                  <iframe src={img.External_Video} title={img.External_Video}/>
                </div>
              )
          })}
        </div>
      </div>
      {showModal && <ImageModal data={modalData} showModal={showModal} setShowModal={setShowModal}/>}
    </div>
  )
}