import './StyleSheets/publications.css'
import { useEffect,useState } from 'react'
import {getPublications} from '../../../Network/request_handlers'
import MobileNavigationBar from '../../Navigation/mobile_navigation_bar'
import { PublicationCard } from '../../General/Cards/publicationCard'
import MobilePublicationPage from './MobilePublicationPage'
export default function MobilePublicationsPage(){
  const [publicationData,setPublicationData]       = useState([])
  const [currentPublication,setCurrentPublication] = useState(null)

  useEffect(()=>{
    getPublications().then((res)=>{
      res.status===200 &&
      setPublicationData((i)=>[...i,...res.data])
    })
  },[])

  return(
    !currentPublication ?
    <div className='mobile-screen-container'>
      <title>Publications</title>
      <MobileNavigationBar/>
      <div className='publications-container'>
      <h1 className='publications-title'>Publications</h1>
        <div className='publications'>
          {
            publicationData.map((publication,i)=>{
              return(
                <PublicationCard publication={publication} setCurrentPublication={setCurrentPublication} key={i}/>
              )
            })
          }
        </div>
      </div>
    </div>:
    <MobilePublicationPage publication={currentPublication} setCurrentPublication={setCurrentPublication}/>

  )
}