import { subSleep } from "../../../AnimationUtilites/sleep";
import cleanUpAnimation from "../../../AnimationUtilites/clean_up_animation";

export default function mobileNavAnimationHandler(element,clicked,extended,setExtended){
  clicked ? setExtended(true) : setExtended(false)
  clicked ? openMenu(element) : closeMenu(element,extended)
}

function openMenu(element){
  element.style.display = 'block'
  element.style.animationName='open'
  subSleep(250).then(()=>{
    Array.from(element.children).forEach((child)=>{
      child.style.display = 'block'
    })
    element.style.width='40%'
    cleanUpAnimation(element)
  })
}

function closeMenu(element,isExtended){
  if(isExtended){
    element.style.animationName='close'
    Array.from(element.children).forEach((child)=>{
      child.style.display = 'none'
    })
    subSleep(250).then(()=>{
      element.style.width='0%'
      element.style.display = 'none'
      cleanUpAnimation(element)
    })
  }
}
