import { useEffect,useState, useRef } from 'react'
import { getHighLights } from '../../../Network/request_handlers'
import {baseURL} from '../../../Utilities/baseURL'
import NavigationBar from '../../Navigation/navigation_bar'
import './Stylesheets/highlights.css'
import fadeInChildren from '../../../Animations/General/fade_in_children'
import { subSleep } from '../../../AnimationUtilites/sleep'

export default function HighLightsPage(){
  const [highlights,setHighlights] = useState({})
  const imagesContainer = useRef(null)

  useEffect(()=>{
    getHighLights().then(res=>{
      if(res.status === 200){
        setHighlights({...res.data[0]})
      }
    }).catch(ers=>{
      throw ers
    })
  },[highlights.length])

  useEffect(()=>{
    if(Object.keys(highlights).length > 0){
      var children = imagesContainer.current.children
      children = [...children]
      fadeInChildren(children)
    }
  },[highlights])

  useEffect(()=>{
    subSleep(5000).then(()=>{
      for (var i = 1; i < 9999; i++){
        window.clearInterval(i);
      }
    })
  },[])
  
  return(
    <>
    <title>Highlights</title>
      <NavigationBar/>
      <div className='hightlights-photos-container' ref={imagesContainer}>
        {
          Object.keys(highlights).map((img,i)=>{
            return(
              (i !== 0) && (typeof highlights[img] === 'string') && 
              <div className='hightlights-photo-container' key={i}>
                <img src={`${baseURL}${highlights[img]}`} alt=""/>
              </div>
            )
          })
        }
      </div>
    </>
  )
};
