import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import { useState,useEffect } from 'react'
import { getPublications } from './Network/request_handlers'
import MobileHighLightsPage from './Components/Home/Highlights/MobileHighLightsPage'
import MobileGalleryPage from './Components/Home/Gallery/MobileGalleryPage'
import MobileAboutPage from './Components/Home/About/MobileAbout'
import MobilePolaroidsPage from './Components/Home/Polaroids/MobilePolaroidsPage'
import MobilePublicationsPage from './Components/Home/Publications/MobilePublicationsPage'
import MobilePublicationPage from './Components/Home/Publications/MobilePublicationPage'
import MobilePageNotFound from './Components/General/MultiPurposeScreens/mobile404'
import MensahStudiosFooter from './Components/General/Footer/mensah_studios_footer'

export default function MobilePlatform(){
  const [publicationData,setPublicationData]= useState([])

  useEffect(()=>{
    getPublications().then((res)=>{
      res.status===200 && setPublicationData(prev=>[...prev,...res.data])
    }).catch((ers)=>{
      console.error(ers)
    })
  },[])

  return(
    <div className='container-fluid p-0'>
      <Router>
        <Routes>
          <Route path='/' element={<MobileHighLightsPage/>} exact/>
          <Route path='Gallery/' element={<MobileGalleryPage/>} exact/>
          <Route path='About/' element={<MobileAboutPage/>} exact/>
          <Route path='Polaroids/' element={<MobilePolaroidsPage/>} exact/>
          <Route path='Publications/' element={<MobilePublicationsPage/>} exact/>
          <Route path='404/' element={<MobilePageNotFound/>}/>
        </Routes>
      </Router>
      <MensahStudiosFooter/>
    </div>
  )
}