import { useState,useEffect,useRef} from 'react';
import { tokenKey } from '../../../Utilities/keys';
import { baseURL, reacctBaseURL } from '../../../Utilities/baseURL';
import { getGallery, putGallery } from '../../../Network/request_handlers';
import '../StyleSheets/admin-gallery-edit.css';
import FormImageContainer from '../../General/ImageContainers/formImageContainer';
import { dataConverter } from '../../../Utilities/data_helpers';
import Spinner from 'react-bootstrap/esm/Spinner';
import InputImagesModal from '../../General/Modals/InputImagesModal';
import { SUPPORTED_IMAGE_EXT } from '../../../Utilities/constants';

export default function AdminGalleryEditPage(){
  const [galleryData,setGalleryData]         = useState([]);
  const [loading,setLoading]                 = useState(true);
  const [imageSetLoading,setImageSetLoading] = useState(false)
  const [imageSet,setImageSet]               = useState([]);
  const [rawData,setRawData]                 = useState({});
  const [pageNumbers,setPageNumbers]         = useState([]);
  const [pageNumber,setPageNumber]           = useState(0);
  const [showModal, setShowModal]            = useState(false)
  const [submiited,setSubmitted]             = useState(false)

  const buttonContainer = useRef(null)

  useEffect(()=>{
    let token = localStorage.getItem('Token')
    tokenKey !== token &&
    window.location.replace(`${reacctBaseURL}/Naana`)
  },[]);

  useEffect(()=>{
    getGallery().then(res=>{
      setGalleryData([...res.data])
    }).catch(ers=>{
      console.log(ers)
    })
  },[])

  useEffect(()=>{
    let index = 0;
    let pagesArray = []
    galleryData.forEach((img,i)=>{
      i%10 === 0 && index++
      i%10 === 0 && pagesArray.push(index)
    })
    setPageNumbers(pagesArray)
  },[galleryData])

  useEffect(()=>{
    let setSize = 10
    let imageList = []
    if(!loading){
      for(let i =0; i<galleryData.length; i+=setSize){
        const chunk = galleryData.slice(i,i+setSize) 
        imageList.push(chunk)
      }
    }
    setImageSet(imageList)
    setLoading(false)
  },[galleryData,loading])

  
  const handleChange = (e)=>{
    let type = e.target.value.split('.')[1].toLowerCase()
    SUPPORTED_IMAGE_EXT.includes(type) ? setRawData({
      ...rawData,
      [`Image|${e.target.name}`]:e.target.files[0],
      [`id|${e.target.name}`]:e.target.id
    }) : alert('This is not an Image. It wont be saved.')
  }

  const handleButtonChange = (i)=>{
    setImageSetLoading(true)
    setPageNumber(i-1)
    setImageSetLoading(false)
  }

  const logOut = ()=>{
    localStorage.removeItem('Token')
    window.location.replace(`${reacctBaseURL}/Naana`)
  };

  const handleSubmit = (e)=>{
    e.preventDefault()
    if(submiited){
      console.log(submiited)
      let body = dataConverter(rawData)
      putGallery(body).catch(ers=>{
        console.error(ers)
        setSubmitted(i=>!i)
      }).finally(()=>{
        setSubmitted(i=>!i)
        window.location.reload()
      })
    }
  }

  const handleButtonClick = ()=>{
     setShowModal(true)
  }
  
  return(
    !loading &&
    <div className='admin-container'>
      <div className='admin-edit-overlay'>
        <div className='admin-home-title-container'>
          <a href={`${reacctBaseURL}/Naana/Home`} className='admin-back-button'>&#8592;</a>
          <h3>Gallery Page View/Edit</h3>
          <button onClick={logOut}>Log Out</button>
        </div>
        <div className='current-page-iframe-container'>
          <iframe src={reacctBaseURL+'/Gallery'} title='Gallery Current View'/>
        </div>
        <form className='gallery-admin-section' onSubmit={handleSubmit}>
          <h1>Gallery Images:</h1>
          <div className='image-display-container'>
            <section className='image-column'>
              {
                imageSet.length > 0 && !imageSetLoading ? imageSet[pageNumber].map((img,i)=>{
                  return i%4===1 && <FormImageContainer ImageSrc={baseURL + img.Image} name={`Image_${pageNumber}_${i}`} onChangeFn={handleChange} key={i} id={img.id} canDelete={true}/>
                }):
                <Spinner animation='grow'/>
              }
            </section>
            <section className='image-column'>
              {
                imageSet.length > 0 && !imageSetLoading ? imageSet[pageNumber].map((img,i)=>{
                  return i%4===2 && <FormImageContainer ImageSrc={baseURL + img.Image} name={`Image_${pageNumber}_${i}`} onChangeFn={handleChange} key={i} id={img.id} canDelete={true}/>
                }):
                <Spinner animation='grow'/>
              }
            </section>
            <section className='image-column'>
              {
                imageSet.length > 0 && !imageSetLoading ? imageSet[pageNumber].map((img,i)=>{
                  return i%4===3 && <FormImageContainer ImageSrc={baseURL + img.Image} name={`Image_${pageNumber}_${i}`} onChangeFn={handleChange} key={i} id={img.id} canDelete={true}/>
                }):
                <Spinner animation='grow'/>
              }
            </section>
            <section className='image-column'>
              {
                imageSet.length > 0 && !imageSetLoading ? imageSet[pageNumber].map((img,i)=>{
                  return i%4===0 && <FormImageContainer ImageSrc={baseURL + img.Image} name={`Image_${pageNumber}_${i}`} onChangeFn={handleChange} key={i} id={img.id} canDelete={true}/>
                }):
                <Spinner animation='grow'/>
              }
            </section>
          </div>
          <div className='page-and-submit-button-container'>
            <section className='pages-container'>
              {
                pageNumbers.map((i,j)=>{
                  return(
                  <button className='page-number' onClick={()=>handleButtonChange(i)} ref={buttonContainer} key={j} id={i} style={{backgroundColor: i===pageNumber+1 && '#fff'}}>
                    {i}
                  </button>
                )})
              }
            </section>
            <section className='button-container'>
              <input type='submit' value='Save Changes' className='admin-submit-button' onClick={(i)=>setSubmitted(true)}/>
              <button className='admin-submit-button' onClick={handleButtonClick}>Add Images</button>
            </section>
          </div>
        </form>
      </div>
      {showModal && <InputImagesModal showModal={showModal} setShowModal={setShowModal} setPageNumber={setPageNumber} pageNumbers={pageNumbers} imageFormats={SUPPORTED_IMAGE_EXT}/>}

    </div>
  )
}