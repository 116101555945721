import { useState, useEffect } from 'react';
import { getPolaroids } from '../../../Network/request_handlers';
import MobileNavigationBar from '../../Navigation/mobile_navigation_bar';
import './StyleSheets/polaroids.css'
import { baseURL } from '../../../Utilities/baseURL';
import ImageModal from '../../General/Modals/imageModal';
import Spinner from 'react-bootstrap/esm/Spinner';

export default function MobilePolaroidsPage(){
  const [showModal,setShowModal]        = useState(false)
  const [modalData,setModalData]        = useState({})
  const [loading,setLoading]            = useState(true)
  const [polaroidData,setPolaroidsData] = useState([])

  useEffect(()=>{
    getPolaroids().then((res)=>{
      res.status===200 &&
      setPolaroidsData((i)=>[...i,...res.data])
    }).finally(()=>{
      setLoading(!loading)
    })
  },[])
  
  const handleClick = async (data)=>{
    await setModalData({...modalData,modalData:data})
    await setShowModal(i=>!i)
  }

  return(
    <div className='mobile-screen-container'>
      <title>Polaroids and Video</title>
      <MobileNavigationBar/>
      <div className='mobile-polaroids-container'>
        <h1 className='polaroids-title'>Polaroids & Videos</h1>
        {!loading ?
        <div className='polaroids-videos'>
          {
            polaroidData.map((img,i)=>{
              let hasNoImage = !img?.Image
              if(img.Image){
                return(
                  <div className='polaroid' key={i}>
                    <img src={`${baseURL}${img.Image}`} alt={i} onClick={()=>handleClick(img)}/>
                  </div>
                )
              }else if(img.Video && hasNoImage){
                return(
                  <div className='polaroid-video' key={i}>
                    <video src={`${baseURL}${img.Video}`} alt={i} controls autoPlay/>
                  </div>
                )
                
              }else if(img.External_Video && hasNoImage){
                return(
                <div className='polaroid-video' key={i}>
                  <iframe src={img.External_Video} title={img.External_Video}/>
                </div>
                )
              }
            })
          }
        </div>:
        <>
        <Spinner animation='grow' variant='dark'/>
        <Spinner animation='grow' variant='dark'/>
        <Spinner animation='grow' variant='dark'/>
        </>}
      </div>
      {showModal && <ImageModal data={modalData} showModal={showModal} setShowModal={setShowModal}/>}
    </div>
  )
}