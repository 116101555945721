import cleanUpAnimation from "../../AnimationUtilites/clean_up_animation";
import { subSleep } from "../../AnimationUtilites/sleep";

export default function navItemAnimationHandler(navItem,isClicked,isMobile){
  if(isClicked && isMobile){
    navItem.style.animationName = 'appear'
    subSleep(1000).then(()=>{
      navItem.style.opacity='1'
      cleanUpAnimation(navItem)
    })
  }else if(!isMobile && navItem){
    navItem.style.animationName = 'appear'
    subSleep(1000).then(()=>{
      navItem.style.opacity='1'
      cleanUpAnimation(navItem)
    })
  }
}