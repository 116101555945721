import Modal from 'react-bootstrap/Modal'
import { useState, useRef} from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import { postPolaroids } from '../../../Network/request_handlers';
import { dataConverter } from '../../../Utilities/data_helpers';

export const PostModal = ({showModal,setShowModal,videoFormats,imageFormats})=>{
  const [rawData,setRawData]      = useState({});
  const [type,setType]            = useState('')
  const [submitted,setSubmitted]  = useState(false)

  const externalVideoContainer = useRef(null)
  const videoContainer = useRef(null)
  const imageContainer = useRef(null)
  const externalVideoCheckBoxContainer = useRef(null)
  const videoCheckBoxContainer = useRef(null)
  const imageCheckBoxContainer = useRef(null)

  const handleChange = (e)=>{
    if(e.target.name==='aVideo'){
      setRawData({})
      if(e.target.checked){
        setType('Video')
        imageContainer.current.disabled = true
        externalVideoContainer.current.disabled = true
        imageCheckBoxContainer.current.disabled = true
        externalVideoCheckBoxContainer.current.disabled = true
      }else{
        imageContainer.current.disabled = false
        externalVideoContainer.current.disabled = false
        imageCheckBoxContainer.current.disabled = false
        externalVideoCheckBoxContainer.current.disabled = false
      }
    }else if(e.target.name==='anExternalVideo'){
      setRawData({})
      if(e.target.checked){
        setType('External Video')
        imageContainer.current.disabled = true
        videoContainer.current.disabled = true
        imageCheckBoxContainer.current.disabled = true
        videoCheckBoxContainer.current.disabled = true
      }else{
        imageContainer.current.disabled = false
        imageCheckBoxContainer.current.disabled = false
        videoCheckBoxContainer.current.disabled = false
        videoContainer.current.disabled = false
      }
    }else if(e.target.name==='anImage'){
      setRawData({})
      if(e.target.checked){
        setType('Image')
        videoContainer.current.disabled = true
        externalVideoContainer.current.disabled = true
        videoCheckBoxContainer.current.disabled = true
        externalVideoCheckBoxContainer.current.disabled = true
      }else{
        videoContainer.current.disabled = false
        externalVideoContainer.current.disabled = false
        videoCheckBoxContainer.current.disabled = false
        externalVideoCheckBoxContainer.current.disabled = false
      }
    }
    
    let type = e.target.value.includes('.') && e.target.value.split('.')[1].toLowerCase()

    if(e.target.name==='Video'){
      videoFormats.includes(type) ? setRawData({
        ...rawData,
        [e.target.name]:e.target.files[0]
      }) : alert(`${e.target.value} will not be saved. It is either not supported or this is on the wrong section. Try the Image section.`)
    }else if(e.target.name==='Image'){
      imageFormats.includes(type) ? setRawData({
        ...rawData,
        [e.target.name]:e.target.files[0]
      }): alert(`${e.target.value} will not be saved. It is either not supported or this is on the wrong section. Try the Video section.`)
    }else if(e.target.name==='External_Video'){
      setRawData({
        ...rawData,
        [e.target.name]:e.target.value
      })
    }
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    let oneIsChecked = imageCheckBoxContainer.current.checked || 
    videoCheckBoxContainer.current.checked || 
    externalVideoCheckBoxContainer.current.checked

    if(oneIsChecked){
      let body = dataConverter(rawData)
      postPolaroids(body).catch(ers=>{
        setSubmitted(false)
        console.log(ers)
      }).finally(()=>{
        setSubmitted(false)
        setShowModal(i=>!i)
        window.location.reload()
      })
    }else{
      alert('You must click a checkbox and reinput your data.')
    }
  }

  return(
    <Modal show={showModal} onHide={()=>setShowModal(i=>!i)}>
      <Modal.Header closeButton/>
        <Modal.Title>
          Add New Polaroid/Video
        </Modal.Title>
        <Modal.Body>
          <form onSubmit={handleSubmit} className='post-polaroid-container'>
            <div className='checkbox-container'>
              <section>
                <label htmlFor='anImage'>Image</label>
                <br/>
                <input type='checkbox' name='anImage' onChange={handleChange} ref={imageCheckBoxContainer}/>
              </section>
              <section>
                <label htmlFor='aVideo'>Video</label>
                <br/>
                <input type='checkbox' name='aVideo' onChange={handleChange} ref={videoCheckBoxContainer}/>
              </section>
              <section>
                <label htmlFor='anExternalVideo'>External Video</label>
                <br/>
                <input type='checkbox' name='anExternalVideo' onChange={handleChange} ref={externalVideoCheckBoxContainer}/>
              </section>
            </div>
            <label>Image:</label>
            <input type='file' name='Image' onChange={handleChange} ref={imageContainer}/>

            <label>Video:</label>
            <input type='file' name='Video' onChange={handleChange} ref={videoContainer}/>

            <label>External Video:</label>
            <input type='text' name='External_Video' onChange={handleChange} ref={externalVideoContainer}/>
            {submitted ? <Spinner variant='success' animation='border' className='mt-5'/> : <input type='submit' value={`Create ${type}`} className='mt-5'/>}
          </form>
        </Modal.Body>
    </Modal>
  )
}