import { subSleep } from '../../AnimationUtilites/sleep';
import cleanUpAnimation from '../../AnimationUtilites/clean_up_animation';

export default async function fadeInChildren(children){
  await subSleep(500)
  for(let i=0; i<children.length; i++){
    var child = children[i]
    child.style.display = 'flex'
    await subSleep(300)
  }
}