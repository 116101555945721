// import { isMobile } from 'react-device-detect';
import {useMediaQuery} from 'react-responsive'
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Canvas from './canvas';
import Platform from './platform';
import MobilePlatform from './mobile_platofrm';

function App() {
  const [mobile,setMobile] = useState(false);

  const isMobile = useMediaQuery({
        query: '(max-width:499px)'
      })
      
  useEffect(()=>{
    setMobile(()=>isMobile)
  },[mobile,isMobile])

  const testComponenet = false
  return (
     testComponenet ? <Canvas/> : !mobile ? <Platform/>: <MobilePlatform/>
  );
}

export default App;
