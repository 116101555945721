import './StyleSheets/about.css'
import NavigationBar from '../../Navigation/navigation_bar'
import { useState, useEffect, useRef } from 'react'
import { getAbout } from '../../../Network/request_handlers'
import logo from '../../../Images/ig_logo.png'
import MailLogo from '../../../Images/mail_icon.png'
import { baseURL } from '../../../Utilities/baseURL'
import Aos from 'aos'
import 'aos/dist/aos.css'

export default function AboutPage(){
  const [aboutData,setAboutData] = useState({})

  useEffect(()=>{
    getAbout().then((res)=>{
      if(res.status === 200){
        setAboutData({...res.data[0]})
      }
    }).catch((ers)=>{
      throw ers
    })
  },[])

  useEffect(()=>{
    Aos.init({
      duration:3000
    })
  },[])

  return(
    <>
      <title>About</title>
      <NavigationBar/>
      <div className='about-sections-container' data-aos='fade' data-aos-once='true'>
        <section className='about-section'>
          <section className='text-section'>
            <h1 className='about-me-title'>{aboutData.Page_Title}</h1>
            <div>
             <h2 className='title'>{aboutData.Title}</h2>
            </div>
            <div>
              <p>{aboutData.Description}</p>
            </div>
          </section>
          <section className='img-section'>
            <img src={`${baseURL}${aboutData['Image_1']}`} alt='First About Image'/>
          </section>
        </section>
        <section className='about-section mt-5' data-aos='fade' data-aos-once='true'>
          <section className='img-section'>
            <img src={`${baseURL}${aboutData['Image_2']}`} alt='Second About Image'/>
          </section>
          <section className='text-section'>
            <h1 className='about-me-title'>Statistics</h1>
            <ul className='p-0'>
              <li>Height: {aboutData.Height}</li>
              <li>Weight: {aboutData.Weight}</li>
              <li>Age: {aboutData.Age}</li>
              <li>Eye Color: {aboutData.Eye_Color}</li>
              <li>Natural Hair Color: {aboutData.Natural_Hair_Color}</li>
              <li>Current Hair Color: {aboutData.Current_Hair_Color}</li>
              <li>Shoe Size: {aboutData.Shoe_Size}</li>
              <li>Bust: {aboutData.Bust}</li>
              <li>Hips: {aboutData.Hips}</li>
              <li>Waist: {aboutData.Waist}</li>
              <li>Race: {aboutData.Race}</li>
              <li>Nationality: {aboutData.Nationality}</li>
            </ul>
          </section>
        </section>
        <div className='container-fluid' data-aos='fade' data-aos-once='true'>
          <h1 className='contact-title'>Contact</h1>
          <section className='contact-section'>
            <a href={aboutData.Instagram} target='_blank' rel='noreferrer'>
              <img src={logo} alt='Instagram'/>
            </a>
            <a href={`mailto: ${aboutData.Email}`}>
              <img src={MailLogo} alt='Mail'/>
            </a>
          </section>
        </div>
      </div>
    </>
  )
}