import { useState,useEffect } from 'react';
import { tokenKey } from '../../../Utilities/keys';
import { baseURL, reacctBaseURL } from '../../../Utilities/baseURL';
import { getPublications, updatePublications } from '../../../Network/request_handlers';
import { dataConverter } from '../../../Utilities/data_helpers';
import '../StyleSheets/admin-publication-edit.css'
import Spinner from 'react-bootstrap/esm/Spinner';
import TextAreaLabel from '../../General/FormComponents/textAreaLabelComponent';
import TextInputLabel from '../../General/FormComponents/textInputLabelComponent';
import { SUPPORTED_IMAGE_EXT } from '../../../Utilities/constants';
import { DeletePublicationModal } from '../../General/Modals/deletePublicationModal';
import { CreatePublicationModal } from '../../General/Modals/createPublicationModal';

export default function AdminPublicationsEditPage(){
  const [publications,setPublications]             = useState([]);
  const [whichPublication, setWhichPublication]    = useState(null);
  const [loading,setLoading]                       = useState(true)
  const [currentPublication,setCurrentPublication] = useState({})
  const [rawData,setRawData]                       = useState({})
  const [openCreateModal,setOpenCreateModal]       = useState(false)
  const [openDeleteModal, setOpenDeleteModal]      = useState(false)
  const [deleteId,setDeleteId]                     = useState(null);
  const [modifiedRawData,setModifiedRawData]       = useState({})
  const [submitted,setSubmitted]                   = useState(false)

  useEffect(()=>{
    let token = localStorage.getItem('Token')
    tokenKey !== token &&
    window.location.replace(`${reacctBaseURL}/Naana`)
  },[])

  useEffect(()=>{
    getPublications().then(res=>{
      setPublications([...res.data])
    }).catch(ers=>{
      console.log(ers)
    }).finally(()=>{
      setLoading(!loading)
    })
  },[])
  
  useEffect(()=>{
    if(publications.length > 0){
      var publication = publications.filter((publication)=>
        publication.id == whichPublication
      )
      setCurrentPublication({...publication[0]})
    }
  },[whichPublication])

  const logOut = ()=>{
    localStorage.removeItem('Token')
    window.location.replace(`${reacctBaseURL}/Naana`)
  }

  const handlePublicationChange = (e)=>{
    let badDataFn = (e)=>{
      alert(`${e.target.name} does not support values that arent an extension of .jpg, .jpeg, or .png. This input will not be saved.`)
    }

    let goodDataFn = (e)=>{
      setRawData({
        ...rawData,
        [e.target.name]:e.target.files[0]
      })
    }

    if(e.target.name.includes('Image')){
      let [name,type] = e.target.files[0].name.split('.')
      SUPPORTED_IMAGE_EXT.includes(type.toLowerCase()) ?
      goodDataFn(e) : badDataFn(e)
    }else{
      setRawData({
        ...rawData,
        [e.target.name]:e.target.value
      })
    }
  }

  const handleSubmit  = (e)=>{
    e.preventDefault();
    let body = {...rawData,...modifiedRawData}
    body = dataConverter(body)
    body.append('id',currentPublication.id)
    if(body.get('Title') && body.get('Title').length ==0){
      alert('Title is Mandatory')
    }else if(submitted){
      updatePublications(body).catch(ers=>{
        setSubmitted(false)
        console.log(ers)
      }).finally(()=>{
        setSubmitted(false)
        window.location.reload()
      })
    }
  }

  return(
    <div className='admin-container'>
      <div className='admin-edit-overlay'>
        <div className='admin-home-title-container'>
          <a href={`${reacctBaseURL}/Naana/Home`} className='admin-back-button'>&#8592;</a>
          <h3>Publications Page View/Edit</h3>
          <button onClick={logOut}>Log Out</button>
        </div>
        <div className='current-page-iframe-container'>
          <iframe src={reacctBaseURL+'/Publications'} title='Publications Current View'/>
        </div>
        <div className='publications-edit-section'>
          <h1>{whichPublication ? currentPublication.Title : 'Select a Publication'}</h1>
          <div className='add-publication-section'>
            <button onClick={()=>setOpenCreateModal(true)}>Add Publication</button>
            <button onClick={()=>setWhichPublication(null)}>View All Publications</button>
          </div>
          {!whichPublication &&
          <div className='publications-container mt-3'>
            {
              !loading && publications.map((publication,j)=>{
                return <PublicationContainer publicationData={publication} setPublication={setWhichPublication} setDeleteId={setDeleteId} setOpenDeleteModal={setOpenDeleteModal} key={j}/>
              })
            }
           {openDeleteModal && <DeletePublicationModal openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} deleteId={deleteId}/>}
          </div>}
          {
            whichPublication &&
            <form className='publication-container' onSubmit={handleSubmit}>
              <h1>Publication Cover Photo</h1>
              <div className='cover-photo-container'>
                <div className='cover-photo-image-submit-container'>
                  <section className='cover-photo-image-container'>
                    <img src={baseURL + currentPublication.Cover_Image}/>
                  </section>
                  <section className='input-image-container'>
                    <input type='file' name={'Cover_Image'} onChange={handlePublicationChange}/>
                  </section>
                </div>
              </div>
              <h1>Publication Title</h1>
              <div className='publication-title-container'>
                <TextInputLabel inputName={'Title'} defaultValue={currentPublication.Title} maxLen={50} onChangeFn={handlePublicationChange}/>
              </div>
              <h1>Publication Description</h1>
              <div className='publication-description-container'>
                <TextAreaLabel inputName={'Description'} defaultValue={currentPublication.Description} maxLen={500} onChangeFn={handlePublicationChange}/>
              </div>
              <h1>Publication Images</h1>
              <Publication publication={currentPublication} onChangeFn={handlePublicationChange} modifyRawData={setModifiedRawData}/>
              <div className='submit-button-section'>
                {submitted ? <Spinner variant='success' animation='border'/> : <input type='submit' value='Submit Changes' className='admin-submit-button' onClick={()=>setSubmitted(true)}/>}
              </div>
            </form>
          }
          {openCreateModal && <CreatePublicationModal openCreateModal={openCreateModal} setOpenCreateModal={setOpenCreateModal}/>}
        </div>
      </div>
    </div>
    )
}

const PublicationContainer = ({publicationData, setPublication, setDeleteId, setOpenDeleteModal})=>{
  const [reveal,setReveal] = useState(false)
  const {Cover_Image,Title,Description,id} = publicationData

  const handleDeleteClick = ()=>{
    setDeleteId(publicationData.id)
    setOpenDeleteModal(true)
  }

  return(
    <section className='publication' onMouseEnter={()=>setReveal(true)} onMouseLeave={(()=>setReveal(false))}>
      <section className='close-section'>
        <button onClick={handleDeleteClick}>X</button>
      </section>
      <section className='cover-page-section' style={{backgroundImage:`url(${baseURL+Cover_Image})`}} onClick={()=>setPublication(id)}>
        {
          reveal &&
          <section className='description-section'>
            <h1>{Title}</h1>
            <p>{Description}</p>
          </section>
        }
      </section>
    </section>
  )
}

const Publication = ({publication, onChangeFn, modifyRawData})=>{
  let imageSet = []
  
  Object.keys(publication).forEach((k)=>{
    k.includes('Publication_Image') &&
    imageSet.push(publication[k])
  })
  
  const onDeleteFn =(e,name)=>{
    let element = e.target.parentElement.parentElement
    element.style.border = '2px solid red'
    modifyRawData((i)=>({
      ...i,
      [name]:'delete_image'
    }))
  }

  return(
    <section className='publication-images-section'>
      <section className='publication-section'>
        {imageSet.length ==0 ?
        <Spinner animation='grow' variant='light'/>:
        imageSet.map((img,i)=>{
          let name = i%3==1 && Object.keys(publication).find(key=>publication[key]==img)
          name = img ? name : `Publication_Image_${i+1}`
          return(
            i%3==1 &&
            <section className='publication-form-container' key={i}>
              <section className='close-section'>
                <button onClick={(e)=>onDeleteFn(e,name)} role='form'>X</button>
              </section>
              <section className='publication-image-container'>
                <img src={baseURL+img}/>
              </section>
              <section className='input-image-container'>
                <input type='file' name={name} onChange={onChangeFn}/>
              </section>
            </section>
          )
        })}
      </section>
      <section className='publication-section'>
      {imageSet.length ==0 ?
      <Spinner animation='grow' variant='light'/>:
        imageSet.map((img,i)=>{
          let name = i%3==2 && Object.keys(publication).find(key=>publication[key]==img)
          name = img ? name : `Publication_Image_${i+1}`
          return(
            i%3==2 &&
            <section className='publication-form-container' key={i}>
              <section className='close-section'>
                <button onClick={(e)=>onDeleteFn(e,name)}>X</button>
              </section>
              <section className='publication-image-container'>
                <img src={baseURL+img}/>
              </section>
              <section className='input-image-container'>
                <input type='file' name={name} onChange={onChangeFn}/>
              </section>
            </section>
          )
        })}
      </section>
      <section className='publication-section'>
      {imageSet.length ==0 ?
      <Spinner animation='grow' variant='light'/>:
        imageSet.map((img,i)=>{
          let name = i%3==0 && Object.keys(publication).find(key=>publication[key]==img)
          name = img ? name : `Publication_Image_${i+1}`
          return(
            i%3==0 &&
            <section className='publication-form-container' key={i}>
              <section className='close-section'>
                <button onClick={(e)=>onDeleteFn(e,name)}>X</button>
              </section>
              <section className='publication-image-container'>
                <img src={baseURL+img}/>
              </section>
              <section className='input-image-container'>
                <input type='file' name={name} onChange={onChangeFn}/>
              </section>
            </section>
          )
        })}
      </section>
    </section>
  )
}
