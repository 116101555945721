import { useState, useEffect, useRef} from 'react';
import { tokenKey } from '../../../Utilities/keys';
import { baseURL, reacctBaseURL } from '../../../Utilities/baseURL';
import { PostModal } from '../../General/Modals/createPolaroidModal';
import Spinner from 'react-bootstrap/Spinner'
import { getPolaroids, updatePolaroids, deletePolaroids } from '../../../Network/request_handlers';
import '../StyleSheets/admin-polaroid-edit.css'
import { dataConverter } from '../../../Utilities/data_helpers';
import { SUPPORTED_IMAGE_EXT } from '../../../Utilities/constants';
import { SUPPORT_VIDEO_EXT } from '../../../Utilities/constants';

export default function AdminPolaroidsEditPage(){
  const [polaroidData,setPolaroidData]   = useState([])
  const [rawData,setRawData]             = useState({})
  const [loading,setLoading]             = useState(true)
  const [showPostModal,setShowPostModal] = useState(false)
  const [submiited,setSubmitted]         = useState(false)
  
  useEffect(()=>{
    let token = localStorage.getItem('Token')
    tokenKey !== token &&
    window.location.replace(`${reacctBaseURL}/Naana`)
  },[])

  useEffect(()=>{
    getPolaroids().then((res)=>{
      res.status == 200 && setPolaroidData([...res.data])
    }).catch(ers=>{
      console.error(ers)
    }).finally(()=>{
      setLoading(false)
    })
  },[])

  useEffect(()=>{
    polaroidData.length > 0 && typeof polaroidData[0] !== 'string' && setLoading(!loading)
  },[])

  const logOut = ()=>{
    localStorage.removeItem('Token')
    window.location.replace(`${reacctBaseURL}/Naana`)
  }

  const handleChange = (e)=>{
    if (e.target.name.includes('External_Video')){
      setRawData({
        ...rawData,
        [e.target.name]: e.target.value
      })
    }
    else if(e.target.name.includes('Video')){
      let type = e.target.value.split('.')[1].toLowerCase()
      SUPPORT_VIDEO_EXT.includes(type) ?
      setRawData({
        ...rawData,
        [e.target.name]:e.target.files[0]
      }): e.target.value = null
    }else if(e.target.name.includes('Image')){
      let type = e.target.value.split('.')[1].toLowerCase()
      SUPPORTED_IMAGE_EXT.includes(type) ?
      setRawData({
        ...rawData,
        [e.target.name]:e.target.files[0]
      }): e.target.value = null
    }
  }


  const handleDelete = (id)=>{
    deletePolaroids({id: id}).catch(ers=>{
      console.log(ers)
    }).finally(()=>{
      window.location.reload()
    })
  }

  const handleSubmit = (e)=>{
    e.preventDefault()
    let body = dataConverter(rawData)
    if(submiited){
      updatePolaroids(body)
      .catch(ers=>{
        setSubmitted(false)
        console.error(ers)
      }).finally(()=>{
        setSubmitted(false)
        window.location.reload()
      })
    }
  }

  return(
    <div className='admin-container'>
      <div className='admin-edit-overlay'>
        <div className='admin-home-title-container'>
          <a href={`${reacctBaseURL}/Naana/Home`} className='admin-back-button'>&#8592;</a>
          <h3>Polaroids Page View/Edit</h3>
          <button onClick={logOut}>Log Out</button>
        </div>
        <div className='current-page-iframe-container'>
          <iframe src={reacctBaseURL+'/Polaroids'} title='Polaroids Current View'/>
        </div>
        <div className='polaroid-video-edit-container'>
          <h1>Edit Polaroids and Videos</h1>        
          <form className='polaroid-video-container' onSubmit={handleSubmit}>
            <h2>Polaroids</h2>
            <div className='polaroid-section'>
              {
               !loading ? polaroidData.map((data,i)=>{
                let hasNoImage = !data?.Image
                  return (
                    (data?.Image && !hasNoImage) &&
                    <section className='polaroid-container' key={i}>
                      <section className='close-section'>
                        <button onClick={()=>handleDelete(data.id)}>X</button>
                      </section>
                      <section className='image-container'>
                        <img src={baseURL+data.Image}/>
                      </section>
                      <section className='input-section'>
                        <input type='file' name={`Image|${data.id}`} onChange={handleChange}/>
                      </section>
                    </section>
                  )
                }): <>
                      <Spinner animation='grow' variant='light'/>
                      <Spinner animation='grow' variant='light'/>
                      <Spinner animation='grow' variant='light'/>
                    </>
              }
            </div>
            <h2>Videos</h2>
            <div className='video-section'>
            {
               !loading ? polaroidData.map((data,i)=>{
                  let hasExternalVideo = data?.External_Video
                  let hasVideo = data?.Video
                  let hasNoImage = !data?.Image

                  if(hasExternalVideo &&hasNoImage){
                    return (
                      <section className='video-container' key={i}>
                          <section className='close-section'>
                            <button onClick={()=>handleDelete(data.id)}>X</button>
                          </section>
                          <section className='video'>
                            <iframe src={data.External_Video} title={data.External_Video}/>
                          </section>
                          <section className='input-section'>
                            <input type='text' name={`External_Video|${data.id}`} defaultValue={data.External_Video} onChange={handleChange} maxLength={300}/>
                          </section>
                      </section>
                    )
                  }else if(hasVideo && hasNoImage){
                    return (
                      <section className='video-container' key={i}>
                        <section className='close-section'>
                          <button onClick={()=>handleDelete(data.id)}>X</button>
                        </section>
                        <section className='video'>
                          <video src={baseURL + data.Video} alt={i} controls autoPlay/>
                        </section>
                        <section className='input-section'>
                          <input type='file' name={`Video|${data.id}`} onChange={handleChange}/>
                        </section>
                      </section>
                    )
                  }
                }): <>
                      <Spinner animation='grow' variant='light'/>
                      <Spinner animation='grow' variant='light'/>
                      <Spinner animation='grow' variant='light'/>
                    </>
              }
            </div>
            <div className='submit-button-section'>
              <input type='submit' value='Update' className='admin-submit-button' onClick={()=>setSubmitted(i=>!i)}/>
              <button onClick={()=>setShowPostModal(i=>!i)} role='button' className='admin-submit-button'>Add New</button>

            </div>
            {showPostModal && <PostModal showModal={showPostModal} setShowModal={setShowPostModal} videoFormats={SUPPORT_VIDEO_EXT} imageFormats={SUPPORTED_IMAGE_EXT}/>}
          </form>
        </div>
      </div>
    </div>
    )
}