import MobielNavigationBar from '../../Navigation/mobile_navigation_bar'
import './Styles/general.css'

export default function MobilePageNotFound(){
  return (
  <div className='page-not-found-screen-container'>
    <MobielNavigationBar/>
    <div className='four-o-four-container'>
      <div className='overlay'>
        <h1>Hmmm, it seems you got lost. The page you're looking for doesn't exist.</h1>
      </div>
    </div>
  </div>)
}