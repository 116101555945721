import './StyleSheets/publications.css'
import { useEffect, useState } from 'react'
import MobileNavigationBar from '../../Navigation/mobile_navigation_bar'
import ImageModal from '../../General/Modals/imageModal'
import { subSleep } from '../../../AnimationUtilites/sleep'
import Toast from 'react-bootstrap/Toast'
import { baseURL } from '../../../Utilities/baseURL'


export default function MobilePublicationPage({publication,setCurrentPublication}){
  const [dataList,setDatalist]   = useState([])
  const [showModal,setShowModal] = useState(false)
  const [modalData,setModalData] = useState({})
  const [showToast,setShowToast] = useState(true);


  useEffect(()=>{
    if(publication){
      let dataContainer = []
      Object.keys(publication).forEach((k)=>{
        k.includes('Image') &&
        publication[k] !==null &&
        dataContainer.push(publication[k])
      })
      subSleep(800).then(()=>{
        setDatalist(prev=>[...prev,...dataContainer])
      })
    }
  },[publication])

    useEffect(()=>{
    subSleep(15000).then(()=>{
      setShowToast(false)
    })
  },[])

  const handleClick = async (data)=>{
    await setModalData({...modalData,modalData:data})
    await setShowModal(i=>!i)
  }

  let buttonStyle = {
    fontSize: '3vh',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'thin solid rgba(0,0,0,0)',
    color: '#000'
  }

  return(
    <div className='mobile-screen-container'>
      <title>{publication.Title}</title>
      <MobileNavigationBar/>
      <div className='publications-gallery-container'>
      <h1 className='publications-title'>	<button style={buttonStyle} onClick={()=>setCurrentPublication(null)}>&#8592;</button> {publication.Title}</h1>
        <div className='publications'>
          <Toast show={showToast} onClose={setShowToast}>
            <Toast.Body>Scroll left on each row to view more Images.</Toast.Body>
          </Toast>
          {
            dataList.map((img,i)=>{
              if (i%3 === 0){
                let firstImage = img
                let secondImage =dataList?.[i+1]
                let thirdImage = dataList?.[i+2]  
                return (
                  <div className='publication-row' key={i+'-container'}>
                    {firstImage && <img src={baseURL+firstImage} alt="" key={i} onClick={()=>handleClick(firstImage)}/>}
                    {secondImage && <img src={baseURL+secondImage} alt="" key={i+1} onClick={()=>handleClick(secondImage)}/>}
                    {thirdImage && <img src={baseURL+thirdImage} alt="" key={i+2} onClick={()=>handleClick(thirdImage)}/>}
                  </div>
                )
              }else{
                return null
              }
            })
          }
        </div>
      </div>
      {showModal && <ImageModal data={modalData} Images={dataList} showModal={showModal} setShowModal={setShowModal}/>}
    </div>
  )
}