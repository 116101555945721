import { useState, useEffect, useRef} from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { getGalleryList } from '../../../Network/request_handlers';
import SecondNavigationBar from '../../Navigation/second_navigation_bar'
import './StyleSheets/gallery.css'
import useFetch from '../../../CustomHooks/useFetch';
import ImageModal from '../../General/Modals/imageModal';

export default function GalleryPage(){
  const [showModal,setShowModal] = useState(false)
  const [modalData,setModalData] = useState({})
  const [page, setPage] = useState(1);
  const [scrollPosition,setScrollPosition] = useState(0)
  const { loading, error, hasNext, dataList } = useFetch(getGalleryList,page);
  const galleryContainer = useRef(null)

  useEffect(() => {
    var element = galleryContainer.current
    element.addEventListener('scroll', () => {
      const scrollRemainder = element.scrollHeight - element.clientHeight - element.scrollTop
      setScrollPosition(scrollRemainder);
    });

    return () => {
      element.removeEventListener('scroll', () => {
        const scrollRemainder = element.scrollHeight - element.clientHeight - element.scrollTop
        setScrollPosition(scrollRemainder);
      });
    };
  });

  useEffect(()=>{
    if(hasNext){
      (0 < scrollPosition && scrollPosition < 1) && setPage((i)=>i+1)
      scrollPosition < 0 && setPage((i)=>i+1)  
    }
  },[scrollPosition,hasNext])

  const handleClick = async (data)=>{
    await setModalData({...modalData,modalData:data})
    await setShowModal(i=>!i)
  }

  return(
    <div className='screen-container'>
      <title>Gallery</title>
      <SecondNavigationBar/>
      <div className='gallery-container'>
        <h1 className='gallery-title'>Gallery</h1>
        <div className='gallery' ref={galleryContainer}>
          <div className='gallery-column'>
          {
            dataList.map((img,i)=>{
              return(
                (i%4===1) && <div className='gallery-img-container' key={i}>
                  <img src={img.Image} key={i} alt={`Gallery Pic ${i}`} onClick={()=>handleClick(img)}/>
                </div>
              )
            })
          }
          </div>
          <div className='gallery-column'>
          {
            dataList.map((img,i)=>{
              return(
                (i%4===2) && <div className='gallery-img-container' key={i}>
                  <img src={img.Image} key={i} alt={`Gallery Pic ${i}`} onClick={()=>handleClick(img)}/>
                </div>
              )
            })
          }
          </div>
          <div className='gallery-column'>
          {
            dataList.map((img,i)=>{
              return(
                (i%4===3) && <div className='gallery-img-container' key={i}>
                  <img src={img.Image} key={i} alt={`Gallery Pic ${i}`} onClick={()=>handleClick(img)}/>
                </div>
              )
            })
          }
          </div>
          <div className='gallery-column'>
          {
            dataList.map((img,i)=>{
              return(
                (i%4===0) && <div className='gallery-img-container' key={i}>
                  <img src={img.Image} key={i} alt={`Gallery Pic ${i}`} onClick={()=>handleClick(img)}/>
                </div>
              )
            })
          }
          </div>
        </div>
        <div className='loading-section'>
          {loading && <Spinner animation='border' size='lg'/>}
          {error && <p className='text-danger'>Error!</p>} 
        </div>
      </div>
      {showModal && <ImageModal data={modalData} Images={dataList} showModal={showModal} setShowModal={setShowModal}/>}
    </div>
  )
}