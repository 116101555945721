import { deletePublication } from '../../../Network/request_handlers'
import Modal from 'react-bootstrap/Modal'

export const DeletePublicationModal = ({openDeleteModal,setOpenDeleteModal,deleteId})=>{
  const handlePublicationDelete = ()=>{
    deletePublication({
      id: deleteId
    }).catch(ers=>{
      console.log(ers)
    }).finally(()=>{
      setOpenDeleteModal(i=>!i)
      window.location.reload()
    })
  }
  return(
    <Modal show={openDeleteModal} onHide={()=>setOpenDeleteModal(i=>!i)} size='lg'>
      <Modal.Header closeButton/>
      <Modal.Title>
        <h1 className='text-center text-danger'>Are you sure you want to delete? This is irreversible.</h1>
      </Modal.Title>
      <Modal.Body>
        <div className='delete-container'>
          <button className='yes' onClick={handlePublicationDelete}>Yes</button>
          <button className='no' onClick={()=>{setOpenDeleteModal(i=>!i)}}>No</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}