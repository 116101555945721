import './StyleSheets/nav.css'
import { useRef, useEffect } from 'react'
import logo from '../../Images/ig_logo.png'
import typeIn from '../../Animations/type_in'
import NavItem from './nav_items'

export default function SecondNavigationBar(){
  const logoContainer = useRef(null)
  const navContainer = useRef(null)

  useEffect(()=>{
    typeIn('NAANA',logoContainer.current)
    navContainer.current.style.display = 'flex'
  },[]);
  return (
    <div className='second-nav-bar' ref={navContainer}>
      <div className='nav-item-2'>
        <a href = '/' className='logo' ref={logoContainer}></a>
      </div>
      <NavItem tabName={'Gallery'} href={'/Gallery'} isFirstNav={false}  animationDelay={900}/>
      <NavItem tabName={'About'} href={'/About'} isFirstNav={false}  animationDelay={700}/>
      <NavItem tabName={'Polaroids'} href={'/Polaroids'} isFirstNav={false} animationDelay={500}/>
      <NavItem tabName={'Publications'} href={'/Publications'} isFirstNav={false}  animationDelay={1000}/>
      <div className='nav-item-2'>
        <a href = 'https://instagram.com/nyawson_' className='nav-link' target='_blank' rel="noreferrer">
          <img src={logo} className='logo' alt='logo'/>
        </a>
      </div>
    </div>
  )
}