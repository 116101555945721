import { apiClient } from '../apiClient';

export const getHighLights = () => {
  return apiClient.get('Highlights/API/')
} 

export const updateHighLights = (data) => {
  return apiClient.put('Highlights/API/',data)
} 

export const getAbout = () => {
  return apiClient.get('About/API/')
} 

export const updateAbout = (data) => {
  return apiClient.put('About/API/',data)
} 

export const getGallery = () => {
  return apiClient.get('Gallery/API/')
} 

export const getGalleryList = (data={}) => {
  const url = 'GalleryList/API/'
  return apiClient.get(url,data)
} 

export const postGallery = (data) => {
  return apiClient.post('Gallery/API/',data)
} 

export const putGallery = (data) => {
  return apiClient.put('Gallery/API/',data)
} 

export const deleteGallery = (data) => {
  return apiClient.delete('Gallery/API/',{data:data})
}

export const getPolaroids = () => {
  return apiClient.get('Polaroids/API/')
} 

export const postPolaroids = (data) => {
  return apiClient.post('Polaroids/API/',data)
}

export const updatePolaroids = (data) => {
  return apiClient.put('Polaroids/API/',data)
}

export const deletePolaroids = (data) => {
  return apiClient.delete('Polaroids/API/',{data:data})
}

export const getPublications = () => {
  return apiClient.get('Publications/API/')
} 

export const updatePublications = (data) => {
  return apiClient.put('Publications/API/',data)
} 

export const postPublications = (data) => {
  return apiClient.post('Publications/API/',data)
} 

export const deletePublication = (data)=>{
  return apiClient.delete('Publications/API/',{data:data})
}

export const authenication = (data)=>{
  return apiClient.post('Naana/Authentication/',data)
}