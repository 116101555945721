import { useRef } from "react"

export default function MobileNavMenuIcon({clicked,setClicked}){
  const iconRef = useRef(null)
  
  const handleClick = ()=> {
    iconRef.current.classList.toggle('change')
    setClicked(!clicked)
  }

  return <div className='icon-container' ref={iconRef} onClick={()=>handleClick()}>
    <div className="bar1"/>
    <div className="bar2"/>
    <div className="bar3"/>
  </div>
}