import { useEffect, useState} from 'react';
import { tokenKey } from '../../../Utilities/keys';
import { baseURL, reacctBaseURL } from '../../../Utilities/baseURL';
import { updateAbout,getAbout } from '../../../Network/request_handlers';
import { dataConverter } from '../../../Utilities/data_helpers';
import TextInputLabel from '../../General/FormComponents/textInputLabelComponent';
import TextAreaLabel from '../../General/FormComponents/textAreaLabelComponent';
import NumericInputLabel from '../../General/FormComponents/numericInputLabelComponent';
import FormImageContainer from '../../General/ImageContainers/formImageContainer';
import Spinner from 'react-bootstrap/esm/Spinner';
import '../StyleSheets/admin-about-edit.css'
import { SUPPORTED_IMAGE_EXT } from '../../../Utilities/constants';

export default function AdminAboutEditPage(){
  const [aboutData,setAboutData] = useState({});
  const [rawData, setRawData]    = useState({})
  const [loading,setLoading]     = useState(true)
  const [submit,setSubmit]       = useState(false)
  useEffect(()=>{
    let token = localStorage.getItem('Token')
    tokenKey !== token &&
    window.location.replace(`${reacctBaseURL}/Naana`)
  },[])
  
  useEffect(()=>{
    getAbout().then((res)=>{
      let data = res.data[0];
      setAboutData((a)=>({...a,data}))
    }).catch(ers=>{
      console.error(ers)
    }).finally(()=>{
      setLoading(false)
    })
  },[])

  const logOut = ()=>{
    localStorage.removeItem('Token')
    window.location.replace(`${reacctBaseURL}/Naana`)
  }

  const handleChange = (e)=>{
    if(e.target.name.includes('Image')){
    let type = e.target.value.split('.')[1].toLowerCase()

    SUPPORTED_IMAGE_EXT.includes(type) ? setRawData({
    ...rawData,
    [e.target.name]:e.target.files[0]}) : alert('This is not an Image. It wont be saved.')
    }else{
      setRawData({
        ...rawData,
        [e.target.name]:e.target.value})
    }
  };

  const handleSubmit = (e)=>{
    e.preventDefault()
    setSubmit(true)
    let body = dataConverter(rawData)
    body.append('id',aboutData.data.id)

    updateAbout(body).then(()=>{
      setSubmit(false)
    }).catch(ers=>{
      console.log(ers)
    }).finally(()=>{
      window.location.reload()
    })
  }

  return(
    !loading &&
    <div className='admin-container'>
      <div className='admin-edit-overlay'>
        <div className='admin-home-title-container'>
          <a href={`${reacctBaseURL}/Naana/Home`} className='admin-back-button'>&#8592;</a>
          <h3>About Page View/Edit</h3>
          <button onClick={logOut}>Log Out</button>
        </div>
        <div className='current-page-iframe-container'>
          <iframe src={reacctBaseURL+'/About'} title='About Current View'/>
        </div>
        <form className='admin-edit-container' onSubmit={handleSubmit}>
          <h1>Text:</h1>
          <div className='admin-about-text-container'>
            <TextInputLabel labelName={'About Me Page Title:'} inputName={'Page_Title'} onChangeFn={handleChange} defaultValue={aboutData.data.Page_Title} maxLen={50}/>
            <TextInputLabel labelName={'My Title:'} inputName={'Title'} onChangeFn={handleChange} defaultValue={aboutData.data.Title} maxLen={50}/>
            <TextAreaLabel labelName={'Description'} inputName={'Description'} defaultValue={aboutData.data.Description} maxLen={700} onChangeFn={handleChange}/>
            <NumericInputLabel labelName={'Weight:'} inputName={'Weight'} onChangeFn={handleChange} defaultValue={aboutData.data.Weight}/>
            <TextInputLabel labelName={'Height:'} inputName={'Height'} onChangeFn={handleChange} defaultValue={aboutData.data.Height} maxLen={50}/>
            <NumericInputLabel labelName={'Age:'} inputName={'Age'} onChangeFn={handleChange} defaultValue={aboutData.data.Age}/>
            <TextInputLabel labelName={'Eye Color:'} inputName={'Eye_Color'} onChangeFn={handleChange} defaultValue={aboutData.data.Eye_Color} maxLen={50}/>
            <TextInputLabel labelName={'Natural Hair Color:'} inputName={'Natural_Hair_Color'} onChangeFn={handleChange} defaultValue={aboutData.data.Natural_Hair_Color} maxLen={50}/>
            <TextInputLabel labelName={'Current Hair Color:'} inputName={'Current_Hair_Color'} onChangeFn={handleChange} defaultValue={aboutData.data.Current_Hair_Color} maxLen={50}/>
            <NumericInputLabel labelName={'Shoe Size:'} inputName={'Shoe_Size'} onChangeFn={handleChange} defaultValue={aboutData.data.Shoe_Size}/>
            <NumericInputLabel labelName={'Bust:'} inputName={'Bust'} onChangeFn={handleChange} defaultValue={aboutData.data.Bust}/>   
            <NumericInputLabel labelName={'Hips:'} inputName={'Hips'} onChangeFn={handleChange} defaultValue={aboutData.data.Hips}/>
            <NumericInputLabel labelName={'Waist:'} inputName={'Waist'} onChangeFn={handleChange} defaultValue={aboutData.data.Waist}/>
            <TextInputLabel labelName={'Race:'} inputName={'Race'} onChangeFn={handleChange} defaultValue={aboutData.data.Race} maxLen={50}/>
            <TextInputLabel labelName={'Nationality:'} inputName={'Nationality'} onChangeFn={handleChange} defaultValue={aboutData.data.Nationality} maxLen={50}/>
            <TextInputLabel labelName={'Instagram:'} inputName={'Instagram'} onChangeFn={handleChange} defaultValue={aboutData.data.Instagram} maxLen={50}/>
            <TextInputLabel labelName={'Email:'} inputName={'Email'} onChangeFn={handleChange} defaultValue={aboutData.data.Email} maxLen={50}/>
          </div>
          <h1>Images:</h1>
          <div className='admin-about-images-container'>
            <FormImageContainer name='Image_1' ImageSrc={baseURL + aboutData.data.Image_1} onChangeFn={handleChange}/>
            <FormImageContainer name='Image_2' ImageSrc={baseURL + aboutData.data.Image_2}  onChangeFn={handleChange}/>
          </div>
         {submit ? <Spinner animation='border' variant='success' style={{marginLeft: '40%'}}/> : <input type='submit' value='Save Changes' className='admin-submit-button' style={{marginLeft: '40%'}}/>}
        </form>
      </div>
    </div>
    )
}