import { useState, useEffect, useRef} from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { getGalleryList } from '../../../Network/request_handlers';
import MobileNavigationBar from '../../Navigation/mobile_navigation_bar';
import './StyleSheets/gallery.css'
import useFetch from '../../../CustomHooks/useFetch';
import ImageModal from '../../General/Modals/imageModal';
import Toast from 'react-bootstrap/Toast'
import { subSleep } from '../../../AnimationUtilites/sleep';

export default function MobileGalleryPage(){
  const [showModal,setShowModal]              = useState(false);
  const [modalData,setModalData]              = useState({});
  const [page, setPage]                       = useState(1);
  const [scrollPosition,setScrollPosition]    = useState(0);
  const [showToast,setShowToast]              = useState(true);
  const { loading, error, hasNext, dataList } = useFetch(getGalleryList,page);
  const galleryContainer                      = useRef(null);

  useEffect(() => {
    var element = galleryContainer.current
    element.addEventListener('scroll', () => {
      const scrollRemainder = element.scrollHeight - element.clientHeight - element.scrollTop
      setScrollPosition(scrollRemainder);
    });

    return () => {
      element.removeEventListener('scroll', () => {
        const scrollRemainder = element.scrollHeight - element.clientHeight - element.scrollTop
        setScrollPosition(scrollRemainder);
      });
    };
  });

  useEffect(()=>{
    if(hasNext){
      (0 < scrollPosition && scrollPosition < 1) && setPage((i)=>i+1)
      scrollPosition < 0 && setPage((i)=>i+1)  
    }
  },[scrollPosition,hasNext])

  useEffect(()=>{
    subSleep(15000).then(()=>{
      setShowToast(false)
    })
  },[])

  const handleClick = async (data)=>{
    await setModalData({...modalData,modalData:data})
    await setShowModal(i=>!i)
  }

  return(
    <div className='mobile-screen-container'>
      <title>Gallery</title>
      <MobileNavigationBar/>
      <div className='gallery-container'>
        <h1 className='gallery-title'>Gallery</h1>
        <div className='gallery' ref={galleryContainer}>
          <Toast show={showToast} onClose={setShowToast}>
            <Toast.Body>Scroll left on each row to view more Images.</Toast.Body>
          </Toast>
          {
            dataList.map((img,i)=>{
              if (i%3 === 0){
                let firstImage = img
                let secondImage =dataList?.[i+1]
                let thirdImage = dataList?.[i+2]  
                return (
                  <div className='gallery-row' key={i-3}>
                    {firstImage?.Image && <img src={firstImage?.Image} alt="" key={i} onClick={()=>handleClick(firstImage)}/>}
                    {secondImage?.Image && <img src={secondImage?.Image} alt="" key={i+1} onClick={()=>handleClick(secondImage)}/>}
                    {thirdImage?.Image && <img src={thirdImage?.Image} alt="" key={i+2} onClick={()=>handleClick(thirdImage)}/>}
                  </div>
                )
              }else{
                return null
              }
            })
          }
        </div>
        <div className='loading-section'>
          {loading && <Spinner animation='border' size='lg'/>}
          {error && <p className='text-danger'>Error!</p>} 
        </div>
      </div>
      {showModal && <ImageModal data={modalData} Images={dataList} showModal={showModal} setShowModal={setShowModal}/>}
    </div>
  )
}