import { useEffect,useRef,useState } from "react"
import './StyleSheets/nav.css'
import typeIn from "../../Animations/type_in"
import MobileNavMenuIcon from "./mobile_menu_icon"
import MobileNavItem from "./mobile_nav_item"
import mobileNavAnimationHandler from "../../Animations/Navigation/MobileNavBarAnimatons/mobile_nav_animation_handler"


export default function MobileNavigationBar(){
  const [clicked,setClicked] = useState(false)
  const [isExtended,setIsExtended] = useState(false)
  const mobileNavTitle = useRef(null)
  const mobileNavContainer = useRef(null)


  useEffect(()=>{
    typeIn('NAANA YAWSON',mobileNavTitle.current)
  },[])

  useEffect(()=>{
    mobileNavAnimationHandler(mobileNavContainer.current,clicked,isExtended,setIsExtended)
  },[clicked])

  return(
    <>
      <div className='mobile-nav-bar'>
        <a href="/" className='logo' ref={mobileNavTitle}></a>
        <MobileNavMenuIcon clicked={clicked} setClicked={setClicked}/>
      </div>
      <div className='mobile-nav-menu' ref={mobileNavContainer}>
        <MobileNavItem tabName={'Polaroids'} href={'/Polaroids'} isFirstNav={false} animationDelay={500} isClicked={clicked}/>
        <MobileNavItem tabName={'About'} href={'/About'} isFirstNav={false}  animationDelay={700} isClicked={clicked}/>
        <MobileNavItem tabName={'Gallery'} href={'/Gallery'} isFirstNav={false}  animationDelay={900} isClicked={clicked}/>
        <MobileNavItem tabName={'Publications'} href={'/Publications'} isFirstNav={false}  animationDelay={1000} isClicked={clicked}/>
      </div>
    </>
  )
}