import { useEffect,useState, useRef } from 'react'
import { getHighLights } from '../../../Network/request_handlers'
import {baseURL} from '../../../Utilities/baseURL'
import MobileNavigationBar from '../../Navigation/mobile_navigation_bar'
import './Stylesheets/highlights.css'
import { subSleep } from '../../../AnimationUtilites/sleep'
import Spinner from 'react-bootstrap/esm/Spinner'

export default function MobileHighLightsPage(){
  const [highlights,setHighlights]      = useState([])
  const [loading,setloading]            = useState(true)
  const [images,setImages]              = useState([])
  const [stopScroll, setStopScroll]     = useState(false)
  const [intervalFn,setIntervalFn]      = useState(undefined)
  const imagesContainer = useRef(null)

  useEffect(()=>{
    getHighLights().then(res=>{
      if(res.status === 200){
        setHighlights({...res.data[0]})
      }
    }).then(()=>{
      let keys =[]
      Object.keys(highlights).forEach((key)=>{
        typeof highlights[key] === 'string' &&
        keys.push(key)
      })
      setImages(([...keys]))
    }).finally(()=>{
       setloading(false)
    })
  },[highlights.length])

  useEffect(()=>{
    if(!loading && images.length > 0 && !stopScroll){
      let scrollWidth = imagesContainer.current.scrollWidth - imagesContainer.current.clientWidth
      setIntervalFn(setInterval(()=>{
        imagesContainer.current.scrollLeft !== scrollWidth-10 ?
        imagesContainer.current.scrollTo(imagesContainer.current.scrollLeft+1,0):
        setStopScroll(true)
      },1))
    }else if(stopScroll && intervalFn){
      setIntervalFn(clearInterval(intervalFn))
    }
  },[images,stopScroll])

  const haltScroll = ()=>{
    setStopScroll(true)
    setIntervalFn(clearInterval(intervalFn))
  }

  return(
    <>
      <MobileNavigationBar/>
      {!loading ?
      <div className='mobile-hightlights-photos-container' ref={imagesContainer} onClick={haltScroll}>
        {
          Object.keys(highlights).map((img,i)=>{
            return(
              (i !== 0) && (typeof highlights[img] === 'string') && 
              <div className='hightlights-photo-container' key={i}>
                <img src={`${baseURL}${highlights[img]}`} alt=""/>
              </div>
            )
          })
        }
      </div>: <Spinner animation='grow' variant='dark'/>}
    </>
  )
};
