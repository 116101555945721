import { useState } from 'react'
import { postPublications } from '../../../Network/request_handlers'
import Modal from 'react-bootstrap/Modal'
import TextAreaLabel from '../../General/FormComponents/textAreaLabelComponent';
import TextInputLabel from '../../General/FormComponents/textInputLabelComponent';
import Spinner from 'react-bootstrap/esm/Spinner';
import { SUPPORTED_IMAGE_EXT } from '../../../Utilities/constants';
import { dataConverter } from '../../../Utilities/data_helpers';

export const CreatePublicationModal = ({openCreateModal,setOpenCreateModal})=>{
  const [rawData,setRawData]      = useState({})
  const [submitted,setSubmitted]  = useState(false)

  const handlePublicationChange = (e)=>{
    let badDataFn = (e)=>{
      alert(`${e.target.name} does not support values that arent an extension of .jpg, .jpeg, or .png. This input will not be saved.`)
    }

    let goodDataFn = (e)=>{
      setRawData({
        ...rawData,
        [e.target.name]:e.target.files[0]
      })
    }

    if(e.target.name.includes('Image')){
      let [name,type] = e.target.files[0].name.split('.')
      SUPPORTED_IMAGE_EXT.includes(type.toLowerCase()) ?
      goodDataFn(e) : badDataFn(e)
    }else{
      setRawData({
        ...rawData,
        [e.target.name]:e.target.value
      })
    }
  }

  const handleSubmit = (e)=>{
    e.preventDefault()
    let body = dataConverter(rawData)
    postPublications(body).catch(ers=>{
      setSubmitted(false)
      console.log(ers)
    }).finally(()=>{
      setSubmitted(false)
      setOpenCreateModal(i=>!i)
      window.location.reload()
    })
  }

  return(
  <Modal show={openCreateModal} onHide={()=>setOpenCreateModal(i=>!i)} size={'lg'}>
    <Modal.Header closeButton/>
    <Modal.Title>
      <h1 className='text-center'>Create Publication</h1>
    </Modal.Title>
    <Modal.Body>
      <form className='create-publication-container' onSubmit={handleSubmit}>
        <TextInputLabel labelName={'Title'} inputName={'Title'} maxLen={50} onChangeFn={handlePublicationChange} required/>
        <TextAreaLabel labelName={'Description'} inputName={'Description'} maxLen={500} onChangeFn={handlePublicationChange}/>
        <label>Cover Image</label>
        <input type='file' name='Cover_Image' onChange={handlePublicationChange} required/>
        <label>Publication Image 1</label>
        <input type='file' name='Publication_Image_1' onChange={handlePublicationChange}/>
        <label>Publication Image 2</label>
        <input type='file' name='Publication_Image_2' onChange={handlePublicationChange}/>
        <label>Publication Image 3</label>
        <input type='file' name='Publication_Image_3' onChange={handlePublicationChange}/>
        <label>Publication Image 4</label>
        <input type='file' name='Publication_Image_4' onChange={handlePublicationChange}/>
        <label>Publication Image 5</label>
        <input type='file' name='Publication_Image_5' onChange={handlePublicationChange}/>
        <label>Publication Image 6</label>
        <input type='file' name='Publication_Image_6' onChange={handlePublicationChange}/>
        <label>Publication Image 7</label>
        <input type='file' name='Publication_Image_7' onChange={handlePublicationChange}/>
        <label>Publication Image 8</label>
        <input type='file' name='Publication_Image_8' onChange={handlePublicationChange}/>
        <label>Publication Image 9</label>
        <input type='file' name='Publication_Image_9' onChange={handlePublicationChange}/>
        {submitted ? <Spinner animation='border' variant='success'/> : <input type='submit' value='Submit Publication' onClick={()=>setSubmitted(true)}/>}
      </form>
    </Modal.Body>
  </Modal>
  )
}