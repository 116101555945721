import { useEffect,useRef } from "react"
import navItemAnimationHandler from "../../../Animations/Navigation/nav_item_animation_handler"
import { subSleep } from "../../../AnimationUtilites/sleep"

export default function ImgNavItem({img,href,animationDelay}){
  const navItemContainer = useRef(null)

  useEffect(()=>{
    subSleep(animationDelay).then(()=>{
      navItemAnimationHandler(navItemContainer.current)
    })
  },[])
  
  return(
    <>
      <a href={href} className='nav-items' target='_blank' rel="noreferrer" ref={navItemContainer}>
        <img src={img} className='logo' alt='Social Media Link'/>
      </a>
    </>
  )
}