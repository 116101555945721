import { useRef,useEffect} from "react"
import typeIn from "../../../Animations/type_in"

export default function HeaderNavItem({href,animationDelay}){
  const logoContainer = useRef(null)

  useEffect(()=>{
    typeIn('NAANA',logoContainer.current)
  },[])

  return(
    <div className='nav-bar-divider-left justify-content-start'>
      <a href={href} className='logo' ref={logoContainer}></a>
    </div>
  )
}