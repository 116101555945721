import {useRef,useEffect,} from 'react'
import { subSleep } from '../../../AnimationUtilites/sleep'
import navItemAnimationHandler from '../../../Animations/Navigation/nav_item_animation_handler'

export default function BaseNavItem({tabName,href,animationDelay}){
  const navItemContainer = useRef(null)

  useEffect(()=>{
    subSleep(animationDelay+1000).then(
      navItemAnimationHandler(navItemContainer.current)
    )
  },[])

  return(
    <>
      <a href={href} className='nav-item-1' ref={navItemContainer}>{tabName}</a>
    </>
  )
}