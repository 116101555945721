
import { subSleep } from "../../../AnimationUtilites/sleep";
import { useEffect, useRef, useState } from "react";
import swipeDetector from "../../../AnimationUtilites/swipe_detector";
import './StyleSheets/image-modal.css'

export default function MobileImageCarousel({Images,canSwipe=false, showPointers=true, showIndicators=true}){
  const [imageIdx,setImageIdx] = useState(0);

  const caroselContainer = useRef(null);
  const imageContainer   = useRef(null);

  useEffect(()=>{
    if(canSwipe){
      swipeDetector(caroselContainer.current,(dir)=>{
        dir==='l' && handleRightClick()
        dir==='r' && handleLeftClick()
      })
    }else{
      handleImageTransition()
    }
  })

  useEffect(()=>{
    // console.log(imageIdx)
  },[imageIdx])
  // TODO: Get handle of dissappear and reappear with new image

  const handleImageTransition = async ()=>{
    var idx = 0
    while(!canSwipe){
      // console.log(`index is ${idx}, imageidx is ${imageIdx}`)
      if(idx===Images.length-1){
        idx-=Images.length-1
      }else{
        idx+=1
      }
      await subSleep(1000).then(()=>{
        console.log(idx)
        // setImageIdx(idx)
      })
      // setImageIdx(idx)
    }
    // for(let i=0; i<Images.length; i++){
    //   imageIdx==0 && console.log(idx)
    //   if(imageIdx===Images.length-1){
    //     await subSleep(3000).then(()=>{
    //       setImageIdx(0)
    //     }).finally(()=>{
    //       setImageIdx(0)
    //       handleImageTransition()  
    //     })
    //   }else{
    //     idx++
    //     await subSleep(3000)
    //     setImageIdx(idx)
    //   }
    // }
  }

  const handleRightClick = ()=>{
    if(imageIdx < Images.length-1){
      let idx = imageIdx+1
      setImageIdx(idx)
    }else{
      setImageIdx(0)
    }
  }

  const handleLeftClick = ()=>{
    if(imageIdx > 0){
      let idx = imageIdx -1
      setImageIdx(idx)
    }else{
      setImageIdx(Images.length-1)
    }
  }

  return(
    <div className='carousel-container' ref={caroselContainer}>
      <section className='image-indicator-container'>
        <section className='image-container'>
          {(canSwipe && showPointers) &&
          <section className='pointers'>
            <span onClick={handleLeftClick}>&#8249;</span>
          </section>}
          <section className='image'>
            <img src={Images[imageIdx]} alt="" ref={imageContainer}/>
          </section>
         {(canSwipe && showPointers) &&
          <section className='pointers'>
            <span onClick={handleRightClick}>&#8250;</span>
          </section>}
        </section>
        {(showIndicators && canSwipe) &&
        <section className='indicator-container'>
          {
            Images.map((img,k)=>{
              return(
                 <Indicator idx={k} currentIdx={imageIdx} setIdx={setImageIdx} key={k}/>
              )
            })
          }
        </section>}
      </section>
    </div>
  )
}

const Indicator = ({currentIdx, idx, setIdx})=>{
  const indicatorContainer = useRef(null)
  
  useEffect(()=>{
    if(currentIdx===idx){
      indicatorContainer.current.classList.add('selected')
    }else{
      Array.from(indicatorContainer.current.classList).includes('selected') && indicatorContainer.current.classList.remove('selected')
    }
  })

  return(
    <span ref={indicatorContainer} onClick={()=>setIdx(idx)}/>
  )
}