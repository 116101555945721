import './StyleSheets/publications.css'
import { useEffect, useState } from 'react'
import SecondNavigationBar from '../../Navigation/second_navigation_bar'
import ImageModal from '../../General/Modals/imageModal'
import { baseURL } from '../../../Utilities/baseURL'
import { subSleep } from '../../../AnimationUtilites/sleep'

export default function PublicationPage({publication,setCurrentPublication}){
  const [dataList,setDatalist] = useState([])
  const [showModal,setShowModal] = useState(false)
  const [modalData,setModalData] = useState({})

  useEffect(()=>{
    if(publication){
      let dataContainer = []
      Object.keys(publication).forEach((k)=>{
        k.includes('Publication_Image') &&
        publication[k] !==null &&
        dataContainer.push(publication[k])
      })
      subSleep(800).then(()=>{
        setDatalist(prev=>[...prev,...dataContainer])
      })
    }
  },[])

  const handleClick = async (data)=>{
    await setModalData({...modalData,modalData:data})
    await setShowModal(i=>!i)
  }

  let buttonStyle = {
    fontSize: '3vw',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'thin solid rgba(0,0,0,0)',
    color: '#000'
  }

  return(
    publication ?
    <div className='screen-container'>
      <title>{publication.Title}</title>
      <SecondNavigationBar/>
      <div className='publications-gallery-container'>
      <h1 className='publications-title'>	<button style={buttonStyle} onClick={()=>setCurrentPublication(null)}>&#8592;</button> {publication.Title}</h1>
        <div className='publications-2'>
          <div className='publications-column'>
            <div className='publications-2-img-container'>
              <img src={`${baseURL}${publication.Cover_Image}`} alt={`Cover Page`} onClick={()=>handleClick(publication)}/>
            </div>
            {
              dataList.map((img,i)=>{
                return(
                  (i%3===1) && <div className='publications-2-img-container' key={i}>
                    <img src={`${baseURL}${img}`} key={i} alt={`Publications Pic ${i}`} onClick={()=>handleClick(img)}/>
                  </div>
                )
              })
            }
          </div>
          <div className='publications-column'>
          {
            dataList.map((img,i)=>{
              return(
                (i%3===2) && <div className='publications-2-img-container' key={i}>
                  <img src={`${baseURL}${img}`} key={i} alt={`Publications Pic ${i}`} onClick={()=>handleClick(img)}/>
                </div>
              )
            })
          }
          </div>
          <div className='publications-column'>
          {
            dataList.map((img,i)=>{
              return(
                (i%3===0) && <div className='publications-2-img-container' key={i}>
                  <img src={`${baseURL}${img}`} key={i} alt={`Publications Pic ${i}`} onClick={()=>handleClick(img)}/>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
      {showModal && <ImageModal data={modalData} Images={dataList} showModal={showModal} setShowModal={setShowModal}/>}
    </div>:<></>
  )
}