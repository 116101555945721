import ImgNavItem from './NavItems/img_nav_item'
import HeaderNavItem from './NavItems/header_nav_item'
import BaseNavItem from './NavItems/base_nav_item'
import SecondBaseNavItem from './NavItems/second_base_nav_item'

export default function NavItem({tabName,href,isFirstNav,icon,animationDelay}){
  return(
  icon ? <ImgNavItem img={icon} href={href} animationDelay={animationDelay}/>
  : isFirstNav ? tabName==='NAANA' ? <HeaderNavItem href={href} animationDelay={animationDelay}/>
  :<BaseNavItem tabName={tabName} href={href} animationDelay={animationDelay}/> 
  : <SecondBaseNavItem tabName={tabName} href={href} animationDelay={animationDelay}/>
  )
}