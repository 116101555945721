import './StyleSheets/nav.css'
import logo from '../../Images/ig_logo.png'
import NavItem from './nav_items'


export default function NavigationBar(){
  return(
    <>
      <div className = 'nav-bar'>
        <NavItem tabName={'NAANA'} href={'/'} isFirstNav={true}/>
        <div className = 'nav-bar-divider-right'>
          <NavItem tabName={'Gallery'} href={'/Gallery'} isFirstNav={true} animationDelay={600}/>
          <NavItem tabName={'About'} href={'/About'} isFirstNav={true} animationDelay={400}/>
          <NavItem tabName={'Polaroids'} href={'/Polaroids'} isFirstNav={true} animationDelay={200}/>
          <NavItem tabName={'Publications'} href={'/Publications'} isFirstNav={true} animationDelay={800}/>
          <NavItem icon={logo} href={'https://instagram.com/nyawson_'} animationDelay={900}/>
        </div>
      </div>
    </>
  )
}