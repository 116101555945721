import { useEffect, useRef } from 'react';
import { tokenKey } from '../../../Utilities/keys';
import { reacctBaseURL } from '../../../Utilities/baseURL';
import HomeExample from '../../../Images/Admin/home_ex_admin.png'
import AboutExample from '../../../Images/Admin/about_ex_admin.png'
import GalleryExample from '../../../Images/Admin/gallery_ex_admin.png'
import PolaroidsExample from '../../../Images/Admin/polaroids_ex_admin.png'
import PublicationsExample from '../../../Images/Admin/publications_ex_admin.png'
import typeIn from '../../../Animations/type_in';
import '../StyleSheets/admin-home.css'

export default function AdminHomePage(){
  const titleContainer = useRef(null)
  useEffect(()=>{
    let token = localStorage.getItem('Token')
    tokenKey !== token &&
    window.location.replace(`${reacctBaseURL}/Naana`)
  },[])

  useEffect(()=>{
    let message = 'Welcome Naana. Choose a page to view/edit'
    typeIn(message,titleContainer.current)
  },[])

  const logOut = ()=>{
    localStorage.removeItem('Token')
    window.location.replace(`${reacctBaseURL}/Naana`)
  }
  return(
    <div className='admin-container'>
      <div className='admin-home-overlay'>
        <div className='admin-home-title-container'>
          <h3 ref={titleContainer}/>
          <button onClick={logOut}>Log Out</button>
        </div>
        <div className='admin-home-row'>
          <a href={`${reacctBaseURL}/Naana/Edit-Home-Page/`} className='admin-home-tile'>
            Home Page
            <img src={HomeExample} alt='Home Page View'/>
          </a>
          <a href={`${reacctBaseURL}/Naana/Edit-About-Page/`} className='admin-home-tile'>
            About Page
            <img src={AboutExample} alt='About Page View'/>
          </a>
        </div>
        <div className='admin-home-row'>
          <a href={`${reacctBaseURL}/Naana/Edit-Gallery-Page/`} className='admin-home-tile'>
            Gallery Page
            <img src={GalleryExample} alt='Gallery Page View'/>
          </a>
        </div>
        <div className='admin-home-row'>
          <a href={`${reacctBaseURL}/Naana/Edit-Polaroids-Page/`} className='admin-home-tile'>
            Polaroids Page
            <img src={PolaroidsExample} alt='Polaroids Page View'/>
          </a>
          <a href={`${reacctBaseURL}/Naana/Edit-Publications-Page/`} className='admin-home-tile'>
            Publications Page
            <img src={PublicationsExample} alt='Publications Page View'/>
          </a>
        </div>
      </div>
    </div>
    )
}